<template>
  <v-app>
    <h3 class="text-center ma-5">{{ $t("会員の皆様へ") }}</h3>
    <div class="ma-2">
      <p
        v-if="this.aboutus != undefined"
        v-html="this.aboutus.translated[this.$i18n.locale]"
      ></p>
      <ul class="ma-2">
        <li>
          <router-link to="/rule">{{
            $t("ご利用規約・プライバシーポリシー")
          }}</router-link>
        </li>
      </ul>
      <p class="ma-2">
        <b>{{ $t("お問い合わせ窓口") }}</b>
        <br />
        {{ $t("お問い合わせは，下記の窓口までお願いいたします。") }}
        <br />
        {{
          $t("住所：〒105-6027 東京都港区虎ノ門4-3-1 城山トラストタワー27階")
        }}
        <br />
        {{ $t("社名：株式会社クリニック.COM") }}
        <br />
        {{ $t("担当部署：「クリニックcom」お問い合わせ窓口") }}
        <br />
        {{ $t("電話番号") }}: <a href="tel:050-3637-3860">050-3637-3860</a
        ><br />
        {{ $t("メール") }} ：<a href="mailto:info@eyes.science"
          >info@eyes.science</a
        >
      </p>
    </div>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  name: "notice",
  data() {
    return {
      aboutus: undefined,
    };
  },
  created: function () {
    this.commonFetchById();
  },
  methods: {
    commonFetchById: async function () {
      const aboutusRef = await Firebase.firestore()
        .collection("translations")
        .doc("Aboutus");
      aboutusRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.aboutus = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No Aboutus document!", doc);
          }
          this.error = true;
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
  },
};
</script>
